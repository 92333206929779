import './article_page.css';
import './expo_page.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import Study from './in_arrivo.png';
import Advgdb from './in_arrivo.png';

function Shp() {

    const navigate = useNavigate(); // Create a navigate function

    const handleButtonClick = () => {
        navigate('/Login'); // Change to your desired URL
    };

    return(

        <div>
            <div className="home-title">
                    <h1> Shapp, webapp per la Geologia</h1>
            </div>

            <div className="geo-container-art">
                 <div className="image-carousel">
                    <div className="carousel-content">
                        <div className="image-and-description">
                            <img
                                src={Study} // Current image based on index
                                className="carousel-image"
                            />
                            <p className="image-description">
                                In arrivo
                            </p>
                        </div>
                    </div>
                </div>

                <div className="im-i2">
                        <div className="title-im">
                           Articolo in fase di realizzaizone
                        </div>
                        <div className="text-im-art">
                              -

                        </div>
                        <div className="title-im">
                           -
                        </div>
                        <div className="text-im-art">
                           -
                        </div>
                        <div className="title-im">
                           -
                        </div>
                        <div className="text-im-art">
                           -
                        </div>
                </div>
            </div>

            <div className="geo-container-art">
                 <div className="image-carousel">
                    <div className="carousel-content">
                        <div className="image-and-description">
                            <img
                                src={Advgdb} // Current image based on index
                                className="carousel-image"
                            />
                            <p className="image-description">
                                In arrivo
                            </p>
                        </div>
                    </div>
                </div>

                <div className="im-i2">
                        <div className="title-im">
                           -
                        </div>
                        <div className="text-im-art">
                           -
                        </div>

                </div>

            </div>
            <div className="descriptions">
            </div>
        </div>

    );
}


export default Shp;