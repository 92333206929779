import './navbar.css';
import 'react-toastify/dist/ReactToastify.css';
import {handleLogout} from './Login';


function Navbar()
{

  return (
    <div className="navbar">
        <div className="dropdown">
            <a href="\">Home</a>
        </div>

        <div className="dropdown">
            <a href="\blog">Blog</a>
        </div>

        <div className="dropdown">
            <button className="dropbtn">Soluzioni
                <i className="fa fa-caret-down"></i>
            </button>
                <div className="dropdown-content">
                    <a href="\Geoutilities">Geo Utilities</a>
                    <a href="\ves">V.E.S.</a>

                </div>
        </div>

        <div className="dropdown">
            <button className="dropbtn">Formazione e servizi
                <i className="fa fa-caret-down"></i>
            </button>
                <div className="dropdown-content">
                    <a href="\Formazione">SkillBuilder</a>
                    <a href="\Snapro">Snap ∩ Pro</a>
                    <a href="\SartoriaDigitale">Sartoria digitale</a>
                </div>
        </div>

        <div className="dropdown">
            <button className="dropbtn">Area personale
                <i className="fa fa-caret-down"></i>
            </button>
                <div className="dropdown-content">
                    <a href="\Login">Login</a>
                    <a onClick={handleLogout}>Logout</a>
                    <a href="\newuser">Registrati</a>
                </div>
        </div>

        <div className="dropdown">
            <a href="\garage_digitale">Garage digitale</a>
        </div>

         <div className="dropdown">
            <a href="\chisono">Dietro al codice</a>
        </div>

        <div className="dropdown">
            <a href="\contatti">Contattaci</a>
        </div>

    </div>
  );
}


export default Navbar;