import './expo_page.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import VDC from './videoc.png';
import VDCP from './videocP.png';


function Formazione() {

    const descriptions = [
        "Mobile App - acquisisci i dati in campo",
        "Description for the second image.",
    ];

    const navigate = useNavigate(); // Create a navigate function

    const handleButtonClick = () => {
        navigate('/video'); // Change to your desired URL
    };

    const handleButtonContact = () => {
        navigate('/contatti'); // Change to your desired URL
    };

    return(

        <div>
            <div className="home-title">
                    <h1> SkillBuilder </h1>
            </div>
            <br/>
                <div className="geo-container">
                 <div className="tools">
                    <div className="blog-preview">
                        <h3 className="preview-title">Videocorsi Free</h3>
                        <img src={VDC} alt="Blog Preview" className="preview-image" />
                        <p onClick={handleButtonClick} className="preview-text">
                            Vai ai corsi
                        </p>
                    </div>
                </div>

                <div className="im-i2">
                        <div className="title-im">
                           Corsi e Videocorsi:
                        </div>

                        <div className="text-im">
                           <br/>
                              - Formazione Online gratuita per l'utilizzo di Geo Utilities e V.E.S.;
                           <br/>
                           <br/>
                              - Videocorsi personalizzati;
                           <br/>
                           <br/>
                              - Corsi personalizzati in sede.
                           <br/>
                           <br/>
                        </div>
                        <br/>
                </div>
                 <div className="tools">
                    <div className="blog-preview">
                        <h3 className="preview-title">Videocorsi e corsi personalizzati</h3>
                        <img src={VDCP} alt="Blog Preview" className="preview-image" />
                        <p onClick={handleButtonContact} className="preview-text">
                            Richiedi
                        </p>
                    </div>
                 </div>
            </div>
            <div className="descriptions">
                        <div className="title-desc">
                           Descrizione:
                        </div>
                        <div className="text-desc">
                              Sezione dedicata videocorsi online per l'utilizzo suite sofHare.
                              Non trovi quello che cerchi? richiedi informazioni dalla sezione "Videocorsi e corsi personalizzati", potrai ricevere videocorsi dedicate
                              alle tematiche di interesse o ricevere supporto direttamente presso la tua sede.
                        </div>
                        <br/>
            </div>
        </div>

    );
}


export default Formazione;
