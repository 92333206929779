import './article_page.css';
import './expo_page.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import map from './mappa_lique.png';
import Advgdb from './liq_vantage.png';

function Lique() {

    const navigate = useNavigate(); // Create a navigate function

    const handleButtonClick = () => {
        navigate('/Login'); // Change to your desired URL
    };

    return(

        <div>
            <div className="home-title">
                    <h1> Liquefazione dei Terreni: Strumenti per la Valutazione del Rischio</h1>
            </div>

            <div className="geo-container-art">
                 <div className="image-carousel">
                    <div className="carousel-content">
                        <div className="image-and-description">
                            <img
                                src={map} // Current image based on index
                                className="carousel-image"
                            />
                            <p className="image-description">
                                Realizzazione colorchart su base IPL
                            </p>
                        </div>
                    </div>
                </div>

                <div className="im-i2">
                        <div className="title-im">
                           Che cosa è la liquefazione?
                        </div>
                        <div className="text-im-art">
                           Quando si parla di liquefazione si indica l’azzeramento di resistenza dei terreni saturi d’acqua a causa di sollecitazioni
                           statiche o dinamiche (es. a seguito di eventi sismici). Tale fenomeno può essere causa della perdita della capacità portante
                           del suolo ed espansioni laterali, causando importanti cedimenti delle strutture sovrastanti al terreno.
                        </div>
                        <div className="title-im">
                           Come si valuta?
                        </div>
                        <div className="text-im-art">
                           L’indice del potenziale di liquefazione IPL viene utilizzato per stimare la suscettibilità del terreno a tale fenomeno.
                           L’IPL è dato dal rapporto tra gli sforzi ciclici a cui il terreno è sottoposto (CSR) e la resistenza ciclica del deposito (CRR).
                           Quest’ultima può essere ricavata da prove cicliche di laboratorio o da prove effettuate in situ; esistono correlazioni empiriche che permettono di desumere
                           il valore di CRR dal numero di colpi nella SPT, dalla resistenza alla punta Qc nelle CPTU o dalla misura della velocità delle onde Vs.
                        </div>
                </div>
            </div>

            <div className="geo-container-art">
                 <div className="image-carousel">
                    <div className="carousel-content">
                        <div className="image-and-description">
                            <img
                                src={Advgdb} // Current image based on index
                                className="carousel-image"
                            />
                            <p className="image-description">
                                confrontro tra diversi lavori eseguiti con e senza applicazioni connesse ad un geodatabase
                            </p>
                        </div>
                    </div>
                </div>

                <div className="im-i2">
                        <div className="title-im">
                          Che strumenti usare?
                        </div>
                        <div className="text-im-art">
                          Virtual Earth Suite permette di stimare il valore IPL da Prove Penetrometriche, e di archiviarne i risultati.
                          La capacità di identificare e mappare le aree a rischio di liquefazione è cruciale per la progettazione sicura e la pianificazione urbana.
                          In questo contesto, Virtual Earth Suite si propone come uno strumento avanzato che consente di creare mappe di pericolosità basate sugli
                          indici di potenziale di liquefazione archiviati, fornendo agli utenti una visione chiara delle zone vulnerabili. Le mappe di pericolosità,
                          infatti, identificano graficamente le aree a rischio liquefazione utilizzando una scala cromatica che indica i vari livelli di pericolosità.
                          Maggiore è il numero di prove e dati inseriti, maggiore sarà l’affidabilità e delle mappe generate.
                          E' possibile integrare le nuove informazioni a mano a mano che vengono archiviate,
                          garantendo così un quadro sempre attuale e preciso del rischio di liquefazione.
                        </div>
                        <div className="title-im">
                          Perchè avere a disposizione uno strumento della valutazione del rischio?
                        </div>
                        <div className="text-im-art">
                          Un aspetto pratico fondamentale è legato alle mappe di pericolosità realizzabili con i dati
                          archiviati le quali possono aiutare nella pianificazione delle indagini successive offrendo una visione
                          d’insieme predittiva rispetto alle potenziali criticità dell’area da indagare. In questo modo, l’utente può ottimizzare
                          le indagini optando su specifiche strumentazioni e prove.
                          Virtual Earth Suite permette di archiviare i dati elaborati per ogni prova all'interno di un database centralizzato,
                          rendendoli sempre accessibili e facilmente consultabili. Questo sistema semplifica notevolmente la gestione delle informazioni,
                          migliorando l'efficienza nella ricerca e nell'utilizzo dei dati.
                        </div>
                </div>

            </div>
            <div className="descriptions">
            </div>
        </div>

    );
}


export default Lique;
