import './expo_page.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import Time from './time.png';
import Profile from './plugin_img.png';
import mobile from './shnapr.png';

function Snapro() {

    const images = [
        Time, // Replace with your image URLs

    ];

    const descriptions = [
        "Invia i dati e risparmia tempo!",
    ];

    const navigate = useNavigate(); // Create a navigate function

    const handleButtonClick = () => {
        navigate('/field_data'); // Change to your desired URL
    };

    const handleButtonshapp = () => {
        navigate('/sHapp'); // Change to your desired URL
    };

    return(

        <div>
            <div className="home-title">
                    <h1> Snap ∩ Pro</h1>
            </div>
            <br/>
            <div className="geo-container">
                 <div className="image-carousel">
                    <div className="carousel-content">
                        <div className="image-and-description">
                            <img
                                src={images} // Current image based on index
                                className="carousel-image"
                            />
                            <p className="image-description">
                                {descriptions}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="im-i2">
                        <div className="title-im">
                           Richiedi elaborazioni dati:
                        </div>

                        <div className="text-im">
                           <br/>
                              - Acquisisci i tuoi dati direttamente in campo con l'app Android sHnapp;
                           <br/>
                           <br/>
                              - Torna in ufficio e allega i dati acquisiti ai punti geolocalizzati salvati mediante l'App sulla piattaforma sHapp;
                           <br/>
                           <br/>
                              - Invia i dati;
                           <br/>
                           <br/>
                              - Ora torna alle tue attività all'elaborazione ci pensiamo noi!.
                           <br/>
                           <br/>
                        </div>
                        <br/>
                </div>
                 <div className="tools">
                    <div className="blog-preview">
                        <h3 className="preview-title">Blog - Mobile App sHnapp</h3>
                        <img src={mobile} alt="Blog Preview" className="preview-image" />
                        <p onClick={handleButtonClick} className="preview-text">
                            Dettagli
                        </p>
                    </div>
                    <button onClick={handleButtonshapp} className="navigate-button">
                        Richiedi elaborazione
                    </button>
                 </div>
            </div>
            <div className="descriptions">
                        <div className="title-desc">
                           Descrizione:
                        </div>
                        <div className="text-desc">
                              Risparmia tempo e fatica, con l'applicazine mobile sHnapp puoi acquisire in un attimo ubicazione e foto della prova che stai eseguendo.
                              Torna in ufficio e allega i dati di prova ai punti acquisiti con l'applicazione mobile sulla piattaforma online shapp, invia i dati
                              e continua a svolgere le tue attività. In poco tempo riceeverai i tuoi dati elaborati ed archiviati nella tua sezione dedicata!
                        </div>
                        <br/>
            </div>
        </div>

    );
}


export default Snapro;
