import './expo_page.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import Dimage from './webgis_img.png';
import Profile from './img_profilo.png';

function Whoim() {

    const images = [
        Profile,
    ];

    const navigate = useNavigate(); // Create a navigate function

    const handleButtonClick = () => {
        navigate('/contatti'); // Change to your desired URL
    };

    return(

        <div>
            <div className="home-title">
                    <h1> Su di me</h1>
            </div>
            <br/>
            <div className="geo-container">

                <div className="image-carousel">
                    <div className="carousel-content">
                        <div className="image-and-description">
                            <img
                                src={images} // Current image based on index
                                className="carousel-image"
                            />
                        </div>
                    </div>
                </div>

                <div className="im-i2">
                        <div className="title-im">
                           Chi sono:
                        </div>
                        <div className="text-im">
                           <br/>
                             Ciao, mi chiamo Umberto Grechi, classe 1988, muovo i miei primi passi come Geometra
                             facendo rilievi topografici. I miei studi proseguono con laurea triennale e magistrale in Geologia,
                             nel corso di questi anni mi specializzo in cartografia e GIS, interessandomi ed aumentando le mie conoscenze sulla programmazione.
                             Entrato nel mondo del lavoro non abbandono ma approfondisco le mie conoscenze di programmatore dedicandomi
                             allo sviluppo di applicativi per i professionisti.
                        </div>
                        <br/>
                </div>

                <div className="im-i2">
                        <div className="title-im">
                           Il progetto:
                        </div>
                        <div className="text-im">
                           <br/>
                              Il progetto sofHare nasce per aiutare i professionisti ad avere
                              strumenti su misura. Archiviazione geospaziale dei dati, strumenti personalizzati e semplicità di utilizzo,
                              sono il Cuore, la mente e l'anima del progetto.

                        </div>
                        <br/>
                </div>
            </div>
            <div className="descriptions">
                <div className="title-desc">
                           Conosciamoci:
                        </div>
                        <div className="text-desc">
                              Vuoi aprofondire qualche argomento o hai domande sui prodotti?
                        </div>
                        <span  className="clickable-text" onClick={handleButtonClick}>
                                    Scrivimi
                              </span>
                        <br/>
            </div>
        </div>

    );
}


export default Whoim;

