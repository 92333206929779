import './expo_page.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import Anapp from './shnapr.png';
import shpp from './shapp.jpg';
import plugin from './geoutilities.jpg';
import gdbves from './gbd_vantage.png';


function Ves() {

    const images = [
        Anapp, // Replace with your image URLs
        shpp,
        plugin
    ];

    const descriptions = [
        "Mobile App - acquisisci i dati in campo",
        "Online App - Visualizza, gestisici e scarica i dati",
        "Desktop Plugin - Elabora e Archivia",
    ];

    const [currentIndex, setCurrentIndex] = useState(0); // State to track the current image index

    // Function to go to the previous image
    const prevImage = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    // Function to go to the next image
    const nextImage = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/plan'); // Change to your desired URL
    };

    const handleButtonblog = () => {
        navigate('/datageo'); // Change to your desired URL
    };

    return(

        <div>
            <div className="home-title">
                    <h1> V.E.S. </h1>
                    <h2> Virtual Earth Suite </h2>
            </div>
            <br/>
            <div className="geo-container">
                 <div className="image-carousel">
                    <button className="arrow left" onClick={prevImage}>
                        &#10094; {/* Left Arrow */}
                    </button>
                    <div className="carousel-content">
                        <div className="image-and-description">
                            <img
                                src={images[currentIndex]} // Current image based on index
                                alt={`Image ${currentIndex + 1}`}
                                className="carousel-image"
                            />
                            <p className="image-description">
                                {descriptions[currentIndex]}
                            </p>
                        </div>
                    </div>
                    <button className="arrow right" onClick={nextImage}>
                        &#10095; {/* Right Arrow */}
                    </button>
                </div>

                <div className="im-i2">
                        <div className="title-im">
                           Elabora e archivia:
                        </div>
                        <div className="text-im">
                           <br/>
                              - Prove penetrometriche;
                           <br/>
                           <br/>
                              - Prove dilatometriche;
                           <br/>
                           <br/>
                              - Sondaggi;
                           <br/>
                           <br/>
                              - Sezioni stratigrafiche;
                           <br/>
                           <br/>
                              - Prove HVSR, MASW e Down Hole;
                           <br/>
                           <div className="title-im">
                           <br/>
                           Calcola:
                           </div>
                           <br/>
                              - Verifica a liquefazione;
                           <br/>
                           <br/>
                              - Cedimenti post sismici free field.
                        </div>
                        <br/>
                </div>
                 <div className="tools">
                    <div className="blog-preview">
                        <h3 className="preview-title">Blog - V.E.S. e geodatabase</h3>
                        <img src={gdbves} alt="Blog Preview" className="preview-image" />
                        <p onClick={handleButtonblog} className="preview-text">
                            Leggi!
                        </p>
                    </div>
                    <button onClick={handleButtonClick} className="navigate-button">
                        Piani
                    </button>
                 </div>
            </div>
            <div className="descriptions">
                        <div className="title-desc">
                           Descrizione:
                        </div>
                        <div className="text-desc">
                              V.E.S. è una Software Suite studiata per chi opera nel settore geologico e geotecnico.
                              Si tratta di uno strumento completo che opera sia in campo, geolocalizzando ed archiviando i punti di prova durante le fasi di acquisizione ,
                              sia in ufficio durante la fase di calcolo e modellazione.
                        </div>
                        <br/>
            </div>
        </div>

    );
}


export default Ves;
