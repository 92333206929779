import './expo_page.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import Sarto from './sartoria.png';

function Sartoria() {

    const images = [
        Sarto, // Replace with your image URLs
    ];

    const descriptions = [
        "Richiedi strumenti su misura",
    ];

    const navigate = useNavigate(); // Create a navigate function

    const handleButtonClick = () => {
        navigate('/contatti'); // Change to your desired URL
    };

    return(

        <div>
            <div className="home-title">
                    <h1> Sartoria Digitale</h1>
            </div>
            <br/>
            <div className="geo-container">
                 <div className="image-carousel">
                    <div className="carousel-content">
                        <div className="image-and-description">
                            <img
                                src={images} // Current image based on index
                                className="carousel-image"
                            />
                            <span  className="clickable-text" onClick={handleButtonClick}>
                                    CONTATTACI
                              </span>
                        </div>
                    </div>
                </div>

                <div className="im-i2">
                        <div className="title-im">
                           Sviluppo su misura:
                        </div>

                        <div className="text-im">
                           <br/>
                              - Funzionalità personalizzate di tool esistenti;
                           <br/>
                           <br/>
                              - Sviluppo di tool dedicati;
                           <br/>
                           <br/>
                              - Personalizza output con il tuo logo;
                           <br/>
                           <br/>
                              - Velocizza le attività in campo con App dedicate.
                           <br/>
                           <br/>
                        </div>
                        <br/>
                </div>
                 <div className="im-i2">
                        <div className="title-im">
                           Realizza la tua piattaforma WebGis:
                        </div>

                        <div className="text-im">
                           <br/>
                              - Sistema informativo territoriale cucito sulle tue esigenze;
                           <br/>
                           <br/>
                              - Archivia i dati e visualizzali in qualsiasi momento da qualsiasi postazione;
                           <br/>
                           <br/>
                              - Rendi possibile la visualizzazione a pià utenti contemporanemanete;
                           <br/>
                           <br/>
                              - Pubblica solo i dati che vuoi;
                           <br/>
                           <br/>
                              - Richiedi tool appositi e fai a meno di costosi Software!
                           <br/>
                           <br/>
                        </div>
                        <br/>
                </div>
            </div>
            <div className="descriptions">
                        <div className="title-desc">
                           Descrizione:
                        </div>
                        <div className="text-desc">
                              Sartoria Digitale è il servizio che calza a pennello sulle tue esigenze. Hai necessità di una nuova funzionalità per i tuoi tool o Plugin Gis?
                              Vuoi personalizzare output e report per velocizzare il tuo lavoro?
                              Non risci a fare a meno della comodità dello smartphone per archivaire dati già in campo o hai necessità di realizzare una piattaforma WebGIs?
                              <br />
                        </div>
                        <br/>
            </div>
        </div>

    );
}


export default Sartoria;
