import './expo_page.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import Arriva from './in_arrivo.png';

function Video() {

    const navigate = useNavigate(); // Create a navigate function

    const handleButtonClick = () => {
        navigate('/video'); // Change to your desired URL
    };

    return(

        <div>
            <div className="home-title">
                    <h1> Videocorsi Free </h1>
            </div>


            <br/>
            <div className="geo-container">

                <div className="tools">
                <div className="blog-preview">
                <h3 className="preview-title">sHnapp</h3>
                <img src={Arriva} alt="Blog Preview" className="preview-image" />
                </div>
                </div>

                <div className="tools">
                <div className="blog-preview">
                <h3 className="preview-title">CPTOffice & Ulik</h3>
                <iframe
                    src="https://www.youtube.com/embed/T88s0OPVjg0"
                    title="YouTube video player"
                    className="preview-video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
                </div>
                </div>

                <div className="tools">
                <div className="blog-preview">
                <h3 className="preview-title">Geodatabse Tools</h3>
                <img src={Arriva} alt="Blog Preview" className="preview-image" />
                </div>
                </div>
            </div>
            <div className="descriptions">
            </div>
        </div>

    );
}


export default Video;
