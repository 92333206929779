import React, { useState, useRef, useEffect } from 'react';
import './shapp_p1.css';
import 'leaflet/dist/leaflet.css';
import './footer-bs.css';
import { MapComponent } from './components/MapComponent';
import { Graph } from './components/piechart';
import { Graphsis } from './components/piechart_sis';
import { DataContext } from './context/DataContext';
import { useNavigate } from 'react-router-dom';
import { PaneQC } from './components/dataview';
import { Panefs } from './components/dataviewfs';
import { PaneVS } from './components/dataviewmodelvs';

function Shapp_p1() {
    const [parQC, setparQC] = useState(null);
    const [parfs, setparfs] = useState(null);
    const [prof, setprof] = useState(null);
    const [parMV, setparMV] = useState(null);
    const [parMH, setparMH] = useState(null);
    const [parVS, setparVS] = useState(null);

    const [selectedMenu, setSelectedMenu] = useState('layer');
    const [layerFilters, setLayerFilters] = useState({
        ProveGeognostiche: true,
        ProveGeofisiche: true,
        CPT: true,
        CPTU: true,
        CPTE: true,
        DMT: true,
        SDMT: true,
        SONDAGGIO: true,
        HVSR: true,
        MASW: true,
        DH: true,
    });

    const [infotabWidth, setInfotabWidth] = useState(280);
    const infotabRef = useRef(null);
    const [isResizing, setIsResizing] = useState(false);
    const startX = useRef(0);
    const startWidth = useRef(0);

    const navigate = useNavigate();

    const updateDataView = (id, qc, fs, h, mv, mh, vs) => {
        setparQC(qc);
        setparfs(fs);
        setprof(h);
        setparMV(mv);
        setparMH(mh);
        setparVS(vs);
    };

    const handleLayerFilterChange = (event) => {
        const { name, checked } = event.target;
        setLayerFilters(prevFilters => {
            const updatedFilters = { ...prevFilters, [name]: checked };

            if (name === 'ProveGeognostiche') {
                updatedFilters.CPT = checked;
                updatedFilters.CPTU = checked;
                updatedFilters.CPTE = checked;
                updatedFilters.DMT = checked;
                updatedFilters.SDMT = checked;
                updatedFilters.SONDAGGIO = checked;
            }
            if (name === 'ProveGeofisiche') {
                updatedFilters.HVSR = checked;
                updatedFilters.MASW = checked;
                updatedFilters.DH = checked;
            }

            if (!updatedFilters.CPT && !updatedFilters.CPTU && !updatedFilters.CPTE && !updatedFilters.DMT && !updatedFilters.SDMT && !updatedFilters.SONDAGGIO) {
                updatedFilters.ProveGeognostiche = false;
            }
            if (!updatedFilters.HVSR && !updatedFilters.MASW && !updatedFilters.DH) {
                updatedFilters.ProveGeofisiche = false;
            }

            return updatedFilters;
        });
    };

    const renderContent = () => {
        console.log('Selected Menu:', selectedMenu);
        switch (selectedMenu) {
            case 'pannelli':
                return (
                    <div className="container-button">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <button onClick={() => navigate('/sHapp_insdata')} type="button" className="button-pane">Richiedi elaborazione</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <button onClick={() => navigate('/sHapp_lstdata')} type="button" className="button-pane">Scarica progetto</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <button onClick={() => navigate('/Ticket')} type="button" className="button-pane">Assistenza</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                );
            case 'layer':
                return (
                    <div>
                        <div className="layer-categories">
                            <div className="category">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="ProveGeognostiche"
                                        checked={layerFilters.ProveGeognostiche}
                                        onChange={handleLayerFilterChange}
                                    />
                                    Prove Geognostiche
                                </label>
                                <div className="inline-filters">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="CPT"
                                            checked={layerFilters.CPT}
                                            onChange={handleLayerFilterChange}
                                        />
                                        <img src="/icon_cpt.png" height="20" alt="CPT" className="filter-image"/>
                                        CPT
                                    </label>
                                </div>
                                <div className="inline-filters">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="CPTU"
                                            checked={layerFilters.CPTU}
                                            onChange={handleLayerFilterChange}
                                        />
                                        <img src="/icon_cptu.png" height="20" alt="CPTU" className="filter-image"/>
                                        CPTU
                                    </label>
                                </div>
                                <div className="inline-filters">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="CPTE"
                                            checked={layerFilters.CPTE}
                                            onChange={handleLayerFilterChange}
                                        />
                                        <img src="/icon_cpte.png" height="20" alt="CPTE" className="filter-image"/>
                                        CPTE
                                    </label>
                                </div>
                                <div className="inline-filters">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="DMT"
                                            checked={layerFilters.DMT}
                                            onChange={handleLayerFilterChange}
                                        />
                                        <img src="/icon_dmt.png" height="20" alt="DMT" className="filter-image"/>
                                        DMT
                                    </label>
                                </div>
                                <div className="inline-filters">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="SDMT"
                                            checked={layerFilters.SDMT}
                                            onChange={handleLayerFilterChange}
                                        />
                                        <img src="/icon_sdmt.png" height="20" alt="SDMT" className="filter-image"/>
                                        SDMT
                                    </label>
                                </div>
                                <div className="inline-filters">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="SONDAGGIO"
                                            checked={layerFilters.SONDAGGIO}
                                            onChange={handleLayerFilterChange}
                                        />
                                        <img src="/icon_sondaggio.png" height="20" alt="SONDAGGIO" className="filter-image"/>
                                        SONDAGGIO
                                    </label>
                                </div>
                            </div>

                            <div className="category">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="ProveGeofisiche"
                                        checked={layerFilters.ProveGeofisiche}
                                        onChange={handleLayerFilterChange}
                                    />
                                    Prove Geofisiche
                                </label>
                                <div className="inline-filters">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="HVSR"
                                            checked={layerFilters.HVSR}
                                            onChange={handleLayerFilterChange}
                                        />
                                        <img src="/icon_hvsr.png" height="20" alt="HVSR" className="filter-image"/>
                                        HVSR
                                    </label>
                                </div>
                                <div className="inline-filters">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="MASW"
                                            checked={layerFilters.MASW}
                                            onChange={handleLayerFilterChange}
                                        />
                                        <img src="/icon_masw.png" height="20" alt="MASW" className="filter-image"/>
                                        MASW
                                    </label>
                                </div>
                                <div className="inline-filters">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="DH"
                                            checked={layerFilters.DH}
                                            onChange={handleLayerFilterChange}
                                        />
                                        <img src="/icon_dh.png" height="20" alt="DH" className="filter-image"/>
                                        DH
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'data':
                return (
                    <div className="container-tb">
                         <div className="pane1-tb">
                             <PaneQC />
                         </div>

                         <div className="pane2-tb">
                             <Panefs />
                         </div>

                         <div className="pane3-tb">
                             <PaneVS />
                        </div>
                    </div>
                );
            case 'statistics':
                console.log('Rendering Statistics');
                return (
                    <div className="piecharts-container">
                        <div className="piechart1">
                            <Graph />
                        </div>
                        <div className="piechart2">
                            <Graphsis />
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (infotabRef.current) {
                const handleWrapper = document.querySelector('.resize-handle');
                if (handleWrapper) {
                    handleWrapper.style.top = `${infotabRef.current.getBoundingClientRect().top}px`;
                    handleWrapper.style.height = `${infotabRef.current.clientHeight}px`;
                }
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [infotabWidth]);

    const handleMouseDown = (event) => {
        setIsResizing(true);
        startX.current = event.clientX;
        startWidth.current = infotabRef.current.offsetWidth;

        const handleMouseMove = (e) => {
            if (isResizing) {
                const newWidth = Math.max(100, startWidth.current + (e.clientX - startX.current));
                setInfotabWidth(newWidth);
            }
        };

        const handleMouseUp = () => {
            setIsResizing(false);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    return (
        <DataContext.Provider value={{ parQC, setparQC, prof, setprof, parfs, setparfs, parMV, setparMV, parMH, setparMH, parVS, setparVS, updateDataView }}>
            <div>
                <div className="Principal">
                    
                    <div className="layoutapp">
                        <div className="infotab" ref={infotabRef} style={{ width: `${infotabWidth}px` }}>
                            <div className="Title-sh">
                                sHApp
                            </div>
                            <hr className="divider" />
                            <div className="menu">
                                <ul>
                                    <li onClick={() => setSelectedMenu('layer')} className={selectedMenu === 'layer' ? 'active' : ''}>Layer</li>
                                    <li onClick={() => setSelectedMenu('data')} className={selectedMenu === 'data' ? 'active' : ''}>Dati</li>
                                    <li onClick={() => setSelectedMenu('statistics')} className={selectedMenu === 'statistics' ? 'active' : ''}>Stato</li>
                                    <li onClick={() => setSelectedMenu('pannelli')} className={selectedMenu === 'pannelli' ? 'active' : ''}>Tools</li>
                                </ul>
                            </div>
                            {renderContent()}
                        </div>
                        <div className="App">
                            <MapComponent filters={layerFilters} />
                            <div
                                    className="resize-handle"
                                    onMouseDown={handleMouseDown}
                                />
                        </div>
                    </div>
                </div>
            </div>
        </DataContext.Provider>
    );
}

export default Shapp_p1;




















