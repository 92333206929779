import React, { Component } from 'react';
import './Login.css';
import { toast, ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';

export default class LostPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
        };
        this.onChange = this.onChange.bind(this);
        this.sendEmail = this.sendEmail.bind(this); // Binding the sendEmail method
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    sendEmail(e) {
        e.preventDefault(); // Prevent the default form submission behavior

        // Show loading indicator
        toast.info("Invio della richiesta in corso...", { autoClose:1000 });

        // Send email using EmailJS
        emailjs.sendForm('service_u317szf', 'template_4appmns', e.target, 'Xl4cHzs5_rTnbe391')
            .then((result) => {
                // Display success message
                toast.success("Richiesta inviata! Ti contatteremo a breve", { autoClose: 2000 });

                // Optionally reset the form here if desired
                e.target.reset();
                this.setState({ email: '' }); // Clear email state
            }, (error) => {
                console.error(error.text);
                // Display error message
                toast.error("Si è verificato un errore durante l'invio della richiesta. Riprova più tardi.", { autoClose: 2000 });
            });
    }

    render() {
        return (
            <div>
                <ToastContainer /> {/* Include ToastContainer for notifications */}
                <div className="home-title">
                    <h1>Reset Password</h1>
                </div>
                <div className="Principal-login">
                    <div className="login-block">
                        <form onSubmit={this.sendEmail}> {/* Use this.sendEmail */}
                            <div>
                                <label htmlFor="email">Email</label>
                            </div>
                            <div>
                                <input
                                    type="email"
                                    placeholder="Enter your email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.onChange} // Corrected onChange usage
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                className="btn-block"
                                style={{ maxWidth: '300px' }}
                            >
                                Invia richiesta
                            </button>
                            <div className="additional-options">
                                <div>
                                    <p>Remember your password?</p>
                                    <a href="/login" className="forgot-password-link">
                                        Ritorna al login
                                    </a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

