import React, { Component } from 'react';
import './Login.css';
import { toast, ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';

export default class Newuser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nome: '',
            cognome: '',
            azienda: '',
            citta: '',
            indirizzo: '',
            email: '',
            password: '',
            confirmPassword: '',
            showPassword: false,           // State for showing/hiding password
            showConfirmPassword: false,    // State for showing/hiding confirm password
        };
        this.onChange = this.onChange.bind(this);
        this.sendEmail = this.sendEmail.bind(this); // Bind sendEmail
        this.togglePassword = this.togglePassword.bind(this);
        this.toggleConfirmPassword = this.toggleConfirmPassword.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    togglePassword() {
        this.setState(prevState => ({ showPassword: !prevState.showPassword }));
    }

    toggleConfirmPassword() {
        this.setState(prevState => ({ showConfirmPassword: !prevState.showConfirmPassword }));
    }

    sendEmail(e) {
        e.preventDefault(); // Prevent the default form submission behavior

        // Check if passwords match
        if (this.state.password !== this.state.confirmPassword) {
            toast.error("Le password non corrispondono!", { autoClose: 2000 });
            return;
        }

        // Show loading indicator
        toast.info("Invio della richiesta in corso...", { autoClose: 1000 });

        // Send email using EmailJS
        emailjs.sendForm('service_u317szf', 'template_4appmns', e.target, 'Xl4cHzs5_rTnbe391')
            .then((result) => {
                // Display success message
                toast.success("Richiesta inviata! Ti contatteremo a breve", { autoClose: 2000 });
                e.target.reset(); // Reset form fields
                this.setState({ email: '' }); // Clear email state
            }, (error) => {
                console.error(error.text);
                // Display error message
                toast.error("Si è verificato un errore durante l'invio della richiesta. Riprova più tardi.", { autoClose: 2000 });
            });
    }

    render() {
        return (
            <div>
                <ToastContainer /> {/* Include ToastContainer for notifications */}
                <div className="home-title">
                    <h1>Benvenuto</h1>
                </div>
                <div className="Principal-login">
                    <div className="login-block">
                        <form onSubmit={this.sendEmail}>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Nome"
                                    name="nome"
                                    value={this.state.nome}
                                    onChange={this.onChange}
                                    required
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Cognome"
                                    name="cognome"
                                    value={this.state.cognome}
                                    onChange={this.onChange}
                                    required
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Enter your company"
                                    name="azienda" // Use lowercase for consistency
                                    value={this.state.azienda}
                                    onChange={this.onChange}
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Città"
                                    name="citta"
                                    value={this.state.citta}
                                    onChange={this.onChange}
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Indirizzo"
                                    name="indirizzo"
                                    value={this.state.indirizzo}
                                    onChange={this.onChange}
                                />
                            </div>
                            <div>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.onChange}
                                    required
                                />
                            </div>

                            <div className="password-input-container">
                                <input
                                    type={this.state.showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    name="password"
                                    value={this.state.password}
                                    onChange={this.onChange}
                                    required
                                />
                                <button
                                    type="button"
                                    className="toggle-password-btn"
                                    onClick={this.togglePassword}
                                >
                                    {this.state.showPassword ? "🙉" : "🙈"}
                                </button>
                            </div>

                            <div className="password-input-container">
                                <input
                                    type={this.state.showConfirmPassword ? "text" : "password"}
                                    placeholder="Conferma Password"
                                    name="confirmPassword" // Match this with the state variable
                                    value={this.state.confirmPassword}
                                    onChange={this.onChange}
                                    required
                                />
                                <button
                                    type="button"
                                    className="toggle-password-btn"
                                    onClick={this.toggleConfirmPassword}
                                >
                                    {this.state.showConfirmPassword ? "🙉" : "🙈"}
                                </button>
                            </div>

                            <button
                                type="submit"
                                className="btn-block"
                                style={{ maxWidth: '300px' }}
                            >
                                Invia richiesta
                            </button>
                        </form>
                    </div>
                </div>
                <div className="descriptions">
                </div>
            </div>
        );
    }
}
