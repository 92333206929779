import './footer-bs.css';
import 'font-awesome/css/font-awesome.min.css';

function Footer()
{

return (

         <div className = "footer-bs">

                <div className="footer">
                     <img src="sofHare_bianco.png" alt="logo_white" width="100%"/>
                </div>

               <div className = "text-int">
                     Dott. Geol. Grechi Umberto - Pianoro 40065 (BO) <br />
               </div>

               <div className = "ref-social">
                        <a href="https://www.linkedin.com/company/sofhare/" target="_blank">
                           <i className = "fa fa-linkedin-square fa_custom fa-2x"></i>
                        </a>
                        {' '}
                        <a href="https://www.youtube.com/channel/UCY9pOpZ5xUXmc0f7TwnAWQg" target="_blank">
                           <i className="fa fa-youtube fa_custom fa-2x" aria-hidden="true" ></i>
                        </a>
               </div>
        </div>

        );
}

export default Footer;
