import './expo_page.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import Shapp from './wbgs_map.png';
import Shnapp from './shnapr.png';
import Dbsrc from './db_blog.png';
import Liq from './liq_1.jpg';
import Penetrometro from './penetrometro.png';


function Blog() {

    const imagesApp = [
        Shapp, // Replace with your image URLs
        Shnapp,
    ];

    const descriptionsApp = [
        "Shapp - webapp per la Geologia",
        "Mobile App - acquisisci i dati in campo",
    ];

    const linksA = [
        '/shapp_edu',  // Replace with the actual link for Dimage
        '/field_data'   // Replace with the actual link for Profile
    ];

    const imagesGDB = [
        Dbsrc, // Replace with your image URLs
    ];

    const descriptionsGDB = [
        "Perche usare un database? Vantaggi e soluzioni",
    ];

    const linksB = [
        '/datageo',  // Replace with the actual link for Dimage
    ];

    const imagesLIB = [
       Liq, // Replace with your image URLs
       Penetrometro
    ];

    const descriptionsLIB = [
        "Liquefazione, indice IPL e come calcolarlo",
        "Elaborare una prova penetrometrica"
    ];

    const linksC = [
        '/elab_liq',  // Replace with the actual link for Dimage
        '/elab_cpt'
    ];

    const [currentIndexApp, setCurrentIndexApp] = useState(0); // State to track the current image index
    const [currentIndexGDB, setCurrentIndexGDB] = useState(0); // State to track the current image index
    const [currentIndexLIB, setCurrentIndexLIB] = useState(0); // State to track the current image index

    // Function to go to the previous image
    const prevImageApp = () => {
        setCurrentIndexApp((prevIndexApp) =>
            prevIndexApp === 0 ? imagesApp.length - 1 : prevIndexApp - 1
        );
    };

    const prevImageGDB = () => {
        setCurrentIndexGDB((prevIndexGDB) =>
            prevIndexGDB === 0 ? imagesGDB.length - 1 : prevIndexGDB - 1
        );
    };

    const prevImageLIB = () => {
        setCurrentIndexLIB((prevIndexLIB) =>
            prevIndexLIB === 0 ? imagesLIB.length - 1 : prevIndexLIB - 1
        );
    };

    // Function to go to the next image
    const nextImageApp = () => {
        setCurrentIndexApp((prevIndexApp) =>
            prevIndexApp === imagesApp.length - 1 ? 0 : prevIndexApp + 1
        );
    };

    const nextImageGDB = () => {
        setCurrentIndexGDB((prevIndexGDB) =>
            prevIndexGDB === imagesGDB.length - 1 ? 0 : prevIndexGDB + 1
        );
    };

    const nextImageLIB = () => {
        setCurrentIndexLIB((prevIndexLIB) =>
            prevIndexLIB === imagesLIB.length - 1 ? 0 : prevIndexLIB + 1
        );
    };

    const navigate = useNavigate(); // Create a navigate function

    const handleButtonClickApp = () => {
        navigate(linksA[currentIndexApp]); // Navigate to the link corresponding to the current image
    };

    const handleButtonClickGDB = () => {
        navigate(linksB[currentIndexGDB]); // Navigate to the link corresponding to the current image
    };

    const handleButtonClickLIB = () => {
        navigate(linksC[currentIndexLIB]); // Navigate to the link corresponding to the current image
    };

    return(

        <div>
            <div className="home-title">
                    <h1> Blog</h1>
            </div>
            <br/>
            <div className="geo-container">
                 <div className="image-carousel">
                    <button className="arrow left" onClick={prevImageApp}>
                        &#10094; {/* Left Arrow */}
                    </button>
                    <div className="carousel-content">
                        <div className="image-and-description">
                        <h3 className="preview-title">App e Plugin</h3>
                            <img
                                src={imagesApp[currentIndexApp]} // Current image based on index
                                alt={`Image ${currentIndexApp + 1}`}
                                className="carousel-image"
                            />
                            <p
                                className="image-blog-description clickable"
                                onClick={handleButtonClickApp} // Make description clickable
                            >
                                {descriptionsApp[currentIndexApp]}
                            </p>
                        </div>
                    </div>
                    <button className="arrow right" onClick={nextImageApp}>
                        &#10095; {/* Right Arrow */}
                    </button>
                    {/* Navigation Button */}
                 </div>

                <div className="image-carousel">
                    <button className="arrow left" onClick={prevImageGDB}>
                        &#10094; {/* Left Arrow */}
                    </button>
                    <div className="carousel-content">
                        <div className="image-and-description">
                        <h3 className="preview-title">Geodatabase e archiviazione</h3>
                            <img
                                src={imagesGDB[currentIndexGDB]} // Current image based on index
                                alt={`Image ${currentIndexGDB + 1}`}
                                className="carousel-image"
                            />
                            <p
                                className="image-blog-description clickable"
                                onClick={handleButtonClickGDB} // Make description clickable
                            >
                                {descriptionsGDB[currentIndexGDB]}
                            </p>
                        </div>
                    </div>
                    <button className="arrow right" onClick={nextImageGDB}>
                        &#10095; {/* Right Arrow */}
                    </button>
                    {/* Navigation Button */}
                 </div>

                 <div className="image-carousel">
                    <button className="arrow left" onClick={prevImageLIB}>
                        &#10094; {/* Left Arrow */}
                    </button>
                    <div className="carousel-content">
                        <div className="image-and-description">
                        <h3 className="preview-title">Articoli</h3>
                            <img
                                src={imagesLIB[currentIndexLIB]} // Current image based on index
                                alt={`Image ${currentIndexLIB + 1}`}
                                className="carousel-image"
                            />
                            <p
                                className="image-blog-description clickable"
                                onClick={handleButtonClickLIB} // Make description clickable
                            >
                                {descriptionsLIB[currentIndexLIB]}
                            </p>
                        </div>
                    </div>
                    <button className="arrow right" onClick={nextImageLIB}>
                        &#10095; {/* Right Arrow */}
                    </button>
                    {/* Navigation Button */}
                 </div>
            </div>
            <div className="descriptions">

            </div>
        </div>

    );
}


export default Blog;