import React, { Component, Fragment, useState, useContext } from 'react';
import {useLocation, useNavigate } from 'react-router-dom';
import './expo_page.css';

import Wbgs_map from './shapp_home.png';
import Logoblu from './logo_blu.png'; // Import your logo image
import Logobianco from './logo_bianco.png'; // Import your logo image
import Skb from './skillbuilder.png';
import Gdig from './garage_digitale.png';
import Dbd from './geodb.png';
import im_ves from './vs_ves.png';

function Home() {

    const navigate = useNavigate(); // Create a navigate function

    const handleButtonshapp = () => {
        navigate('/shapp'); // Change to your desired URL
    };

    const handleButtonBuild = () => {
        navigate('/Formazione'); // Change to your desired URL
    };

    const handleButtonGar = () => {
        navigate('/Login'); // Change to your desired URL
    };

    const handleButtonArg1 = () => {
        navigate('/ves'); // Change to your desired URL
    };

    const handleButtonArg2 = () => {
        navigate('/datageo'); // Change to your desired URL
    };


    return(

        <div>
            <div className="home-title">
                    <div className="logo-container">
                    <img src={Logobianco} alt="Company Logo" className="logo" />
                </div>
            </div>

            <br/>

            <div className="geo-container">



                 <div className="tools">
                    <div className="blog-preview">
                        <h3 className="preview-title">sHapp</h3>
                        <img src={Wbgs_map} alt="Blog Preview" className="preview-image" />
                        <p onClick={handleButtonshapp} className="preview-text">
                            Accedi
                        </p>
                    </div>
                 </div>

                <div className="tools">
                    <div className="blog-preview">
                        <h3 className="preview-title">SkillBuilder</h3>
                        <img src={Skb} alt="Blog Preview" className="preview-image" />
                        <p onClick={handleButtonBuild} className="preview-text">
                            Accedi
                        </p>
                    </div>
                 </div>

                 <div className="tools">
                    <div className="blog-preview">
                        <h3 className="preview-title">Garage  digitale</h3>
                        <img src={Gdig} alt="Blog Preview" className="preview-image" />
                        <p onClick={handleButtonGar} className="preview-text">
                            Accedi
                        </p>
                    </div>
                 </div>
            </div>

            <div className="descriptions-home">
                        <div className="title-desc-home">
                           In Evidenza:
                        </div>
            </div>

            <div className="geo-container-home">

                 <div className="tools">
                    <div className="blog-preview">
                        <h3 className="preview-title">V.E.S.</h3>
                        <img src={im_ves} alt="Blog Preview" className="preview-image" />
                        <p onClick={handleButtonArg1} className="preview-text">
                            Vai!
                        </p>
                    </div>
                 </div>

                 <div className="tools">
                    <div className="blog-preview">
                        <h3 className="preview-title">Geodatabase sH</h3>
                        <img src={Dbd} alt="Blog Preview" className="preview-image" />
                        <p onClick={handleButtonArg2} className="preview-text">
                            Leggi!
                        </p>
                    </div>
                 </div>
            </div>
        </div>

    );
}


export default Home;
